
import { getUserInfo, updateUserInfo } from '@/api/user';
import { parseConcatNumber } from '@/filters';
import { Form } from 'element-ui';
import { Component, Vue } from 'vue-property-decorator';
import { VueDaumPostcode } from 'vue-daum-postcode';

@Component({
  components: {
    VueDaumPostcode,
  },
})
export default class extends Vue {
  mounted() {
    this.getUserInfo();
  }

  private form = {
    actualName: '',
    birth: '',
    gender: 0,
    concatNumber: '',
    dietExperience: false,
    weight: 0,
    height: 0,
    goalWeight: 0,
    goalDate: '',
    activityLevel: 0,
    dietPurpose: '',
    address: '',
    addressDetail: '',
    postCode: '',
    marketingStatus: false,
  }

  private addressVisible = false;

  private rules: any = {
    actualName: [
      { required: true, message: '이름을 입력해주세요.', trigger: ['blur', 'change'] },
    ],
    concatNumber: [
      { required: 'true', message: '연락처를 입력하세요', trigger: ['blur', 'change'] },
    ],
    email: [
      { required: 'true', message: '이메일을 입력하세요', trigger: ['blur', 'change'] },
      { pattern: /^[a-zA-Z0-9+-_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/, message: 'email@email.com 형태로 입력해주세요', trigger: ['blur', 'change'] },
    ],
    address: [
      { required: 'true', message: '주소를 입력하세요', trigger: ['blur', 'change'] },
    ],
    dietExperience: [
      { required: true, message: '식단관리 경험을 체크하세요.', trigger: ['blur', 'change'] },
    ],
    height: [
      { required: true, message: '키를 입력하세요.', trigger: ['blur', 'change'] },
    ],
    weight: [
      { required: true, message: '몸무게를 입력하세요.', trigger: ['blur', 'change'] },
    ],
    goalWeight: [
      { required: true, message: '목표 몸무게를 입력하세요.', trigger: ['blur', 'change'] },
    ],
    goalDate: [
      { required: true, message: '목표 달성 날짜를 입력하세요.', trigger: ['blur', 'change'] },
    ],
    activityLevel: [
      { required: true, message: '활동 수준을 선택하세요.', trigger: ['blur', 'change'] },
    ],
    dietPurpose: [
      { required: true, message: '식단관리 목적을 선택하세요.', trigger: ['blur', 'change'] },
    ],
  };

  private getUserInfo() {
    getUserInfo().then((res) => {
      this.form = res.data;
    });
  }

  private filterConcatNumber() {
    this.form.concatNumber = parseConcatNumber(this.form.concatNumber);
  }

  private handleUpdateUserInfo() {
    (this.$refs.form as Form).validate((valid: boolean) => {
      if (valid) {
        updateUserInfo(this.form).then(() => {
          this.$message.success('회원정보가 수정되었습니다.');
          // this.getUserInfo();
        });
      }
    });
  }

  private handleVisibleAddress() {
    this.addressVisible = !this.addressVisible;
  }

  private handleComplete(result: any) {
    this.form.postCode = result.zonecode;
    this.form.address = result.address;
    this.handleVisibleAddress();
  }
}
